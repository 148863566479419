<template>
    <div class="row">
        <div class="col-12">
            <div class="accordion" id="filterAccordion">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button bold" type="button" data-bs-toggle="collapse" data-bs-target="#filters" aria-expanded="false" aria-controls="filters">
                            {{ $t('events.filters.title') }} ({{ $t('events.filters.click_to_open') }})
                        </button>
                    </h2>

                    <!-- Filter  -->
                    <div id="filters" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#filterAccordion">
                        <div class="accordion-body">
                            <Form @submit="(values) => loadEvents(pagination.page, values)" :validation-schema="schema" ref="eventsForm">
                                <div class="row">
                                    <div class="col-md-3 col-xs-6 col-xxs-12">
                                        <label for="vehicleRegistrationFilter" class="form-label"> {{ $t('events.filters.vehicle_registration') }} </label>
                                        <Field type="text" name="registration" class="form-control" id="vehicleRegistrationFilter" v-model="filters.registration" placeholder="Enter Registration"/>
                                        <ErrorMessage name="registration" class="error-feedback"/>
                                    </div>
                                    <div class="col-md-3 col-xs-6 col-xxs-12">
                                        <label for="siteFilter" class="form-label">{{ $t('events.filters.site') }}</label>
                                            <Multiselect
                                                :class="{'multi-dark': $store.state.auth.status.dark}"
                                                mode="tags"
                                                placeholder="Select a site"
                                                :close-on-select="false"
                                                :searchable="true"
                                                :create-option="true"
                                                :multiple="false"
                                                :options="sites"
                                                label="name"
                                                track-by="id"
                                                name="site"
                                                @select="(value) => loadCameras(value?.id || null)"
                                                @remove="loadCameras()"
                                                v-model="filters.site"
                                            />
                                        <!-- <Field id="siteFilter" class="form-select" v-model='filters.site_id' name="site" as="select">
                                            <option value="" selected> {{ $t('events.filters.select_a_site') }} </option>
                                            <option v-for="site in sites" :key="'site-' + site.id" :value='site.id'>{{ site.name }}</option>
                                        </Field> -->
                                        <ErrorMessage name="site" class="error-feedback"/>
                                    </div>
                                    <div class="col-md-3 col-xs-6 col-xxs-12">
                                        <label for="cameraFilter" class="form-label">{{ $t('events.filters.camera') }}</label>
                                        <Multiselect
                                                :class="{'multi-dark': $store.state.auth.status.dark}"
                                                mode="tags"
                                                placeholder="Select a camera"
                                                :close-on-select="false"
                                                :searchable="true"
                                                :create-option="true"
                                                :multiple="false"
                                                :options="cameras"
                                                label="name"
                                                track-by="id"
                                                name="camera"
                                                id="cameraFilter"
                                                v-model="filters.camera"
                                            />
                                        <ErrorMessage name="camera" class="error-feedback"/>
                                    </div>
                                    <div class="col-md-3 col-xs-6 col-xxs-12">
                                        <label for="directionFilter" class="form-label">{{ $t('events.filters.direction') }}</label>
                                        <Field id="directionFilter" class="form-select" name="direction" as="select" v-model="filters.direction">
                                            <option value="" selected> {{ $t('events.filters.select_a_direction') }} </option>
                                            <option value='reverse'>{{ $t('events.filters.leaving_site') }}</option>
                                            <option value='forward'>{{ $t('events.filters.entering_site') }}</option>
                                        </Field>
                                        <ErrorMessage name="direction" class="error-feedback"/>
                                    </div>
                                </div>
                                <div class="row m-t-xs">
                                    <div class="col-md-3 col-xs-6 col-xxs-12">
                                        <label for="startDateFilter" class="form-label">{{ $t('events.filters.start_date') }}</label>
                                        <Field type="date" name="start_date" class="form-control" id="startDateFilter" v-model="filters.start_date"/>
                                        <ErrorMessage name="start_date" class="error-feedback"/>
                                    </div>
                                    <div class="col-md-3 col-xs-6 col-xxs-12">
                                        <label for="endDateFilter" class="form-label">{{ $t('events.filters.end_date') }}</label>
                                        <Field type="date" name="end_date" class="form-control" id="endDateFilter" v-model="filters.end_date"/>
                                        <ErrorMessage name="end_date" class="error-feedback"/>
                                    </div>
                                    <div class="col-md-3 col-xs-6 col-xxs-12">
                                        <label for="startTimeFilter" class="form-label">{{ $t('events.filters.start_time') }}</label>
                                        <Field type="time" name="start_time" class="form-control" id="startTimeFilter" v-model="filters.start_time"/>
                                        <ErrorMessage name="start_time" class="error-feedback"/>
                                    </div>
                                    <div class="col-md-3 col-xs-6 col-xxs-12">
                                        <label for="endTimeFilter" class="form-label">{{ $t('events.filters.end_time') }}</label>
                                        <Field type="time" name="end_time" class="form-control" id="endTimeFilter" v-model="filters.end_time"/>
                                        <ErrorMessage name="end_time" class="error-feedback"/>
                                    </div>
                                </div>
                                <div class="row m-t-xs">
                                    <div class="col-md-3 col-xs-6 col-xxs-12">
                                        <label for="statusFilter" class="form-label">{{ $t('events.filters.status') }}</label>
                                        <Field name="status" class="form-select" id="statusFilter" v-model="filters.status" as="select">
                                            <option value="" selected disabled> {{$t('events.select_status')}} </option>
                                            <option value="blacklisted"> {{$t('vehicle.blacklisted')}}</option>
                                            <option value="active"> {{$t('global.active')}} </option>
                                        </Field>
                                        <ErrorMessage name="status" class="error-feedback"/>
                                    </div>
                                </div>
                                <div class="row m-t-md" style="justify-content:center;">
                                    <div class="" style="width:auto;">
                                        <button type="submit" :disabled="loading" class="btn btn-primary" style="max-width: 150px;">
                                            <!-- Show spinning icon when dataTable is loading -->
                                            <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
                                            <span>{{ $t('events.filters.search') }}</span>
                                        </button>
                                    </div>
                                    <div class="col-12 width-auto" style="width:auto;">
                                        <button type="reset" :disabled="loading" class="btn btn-secondary" style="max-width: 150px;" @click="clearFilter">
                                            <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
                                            <span>{{ $t('global.clear_filter') }}</span>
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Page Heading -->
            <h1 class="table-heading"> {{ $t('events.events')}} </h1>

            <!-- Pagination -->
            <Pagination v-if="!loading" :showTotal="false" :config="pagination" @search="(page) => loadEvents(page, currentParams)"/>

            <!-- Loading -->
            <div v-show="loading" style="width: 100%; text-align: center;">
                <div class="spinner-border" style="height: 4rem; width: 4rem;" role="status">
                    <span class="visually-hidden">{{ $t('events.loading') }}...</span>
                </div>
            </div>

            <!-- Event Card -->
            <div v-show="!loading" class="row events-container">
                <div v-show="events.length < 1" class="flex-center no-data-container">
                   <p> No events </p>
                </div>
                <div v-for="event in events" :key="'events-' + event.id" class="card" style="width:400px; height:auto; cursor:pointer;" :class="event.status === 'blacklisted' ? 'blacklist-card': ''">
                    <div class="image-container">
                        <!-- <EventImage 
                            v-if="event.image !== null"
                            :filename="event.image"
                            :site_id="event.site.id"
                            :id="'eventimage-' + event.id"
                            :thumb="false"
                            :storage="event.storage_account || 1"
                        /> -->
                        <SasEventImage
                            v-if="event.image !== null"
                            :src="event.image_url"
                            :filename="event.image"
                            :site_id="event.site.id"
                            :id="'eventimage-' + event.id"
                            :thumb="false"
                            :storage="event.storage_account || 1"
                        />
                    </div>
                    <div class="event-information-container" @click="$router.push(`/eventsnew/${event.id}`)">
                        <div class="information-sub-container">
                            <span id="site-name"> {{ event.site.name + ':'}} </span>
                            <!-- <div class="status-container"> <span class="status-container"> {{ event.status}} </span> </div> -->
                             <!-- <span class="status-container"> {{ event.status.toUpperCase()}} </span> -->
                             <span :class="event.status === 'active' ? 'green': 'red'"> {{ event.status.toUpperCase()}} </span>
                        </div>
                        <div class="information-sub-container">
                            <span id="camera-name"> {{ event.camera.name }} </span>
                        </div>
                        <div class="information-sub-container">
                            <span id="camera-direction"> {{event.direction === 'reverse' ? this.$t('events.filters.leaving_site') : this.$t('events.filters.entering_site')}} </span>
                            <Timestamp :time="event.created_at" format="dd/MM/yyyy HH:mm:ss" id="timestamp"/>
                        </div>
                        <div class="information-sub-container btn-container">
                            <span class="vrm bold number-plate-gbr" style="font-size: 16px;">{{event.vehicle.plate}}</span>
                            <button v-if="event.status === 'active' && $can('events-blacklist')" class="btn btn-primary bl-btn" @click.stop="openModal(event)"> <i class="material-icons-outlined"> add</i> {{$t('blacklist.add_to_blacklist')}} </button>
                            <button v-if="event.status === 'blacklisted' && $can('events-unblacklist') && event.blacklist_count > 0" class="btn btn-primary secondary-blue-btn" @click.stop="openUnblacklistModal(event)"> <i class="material-icons-outlined "> close </i> {{$t('blacklist.remove_from_blacklist')}} </button>
                            <span v-if="event.status === 'blacklisted' && $can('events-unblacklist') && event.blacklist_count < 1" class="btn btn-success btn-outlined" style="cursor:default;" v-tooltip="'This vehicle is on the national blacklist, it must be removed by the company that blacklisted it.'">National Blacklist</span>
                        </div>
                    </div>

                </div>
            </div>

            <!-- Pagination -->
            <Pagination v-if="!loading && events.length > 0" :showTotal="false" :config="pagination" @search="(page) => loadEvents(page, currentParams)"/>

        </div>
    </div>

    <teleport to="body">
        <div class="modal fade" id="eventImageModal" tabindex="-1" style="display: none; padding-right: 17px;" aria-modal="true" role="dialog">
        <div class="modal-dialog modal-dialog-centered" style="height: 80vh; width: 75%; max-width: 75%;">
            <div class="img-magnifier-container">
                <!-- <EventImage
                    v-if="currentEvent !== null" 
                    :thumb="false" :filename="currentEvent.image" 
                    :site_id="currentEvent.site.id" 
                    :id="'modaleventimage-' + currentEvent.id"
                /> -->
                <SasEventImage
                    v-if="currentEvent !== null" 
                    :thumb="false" :filename="currentEvent.image" 
                    :site_id="currentEvent.site.id" 
                    :id="'modaleventimage-' + currentEvent.id"
                    :src="currentEvent.image_url"
                />
            </div>
            <p class="closePopup">
            {{ $t('events.press') }} <code>ESC</code> {{ $t('events.event_close') }}
            </p>
        </div>
        </div>
    </teleport>

    <!-- Add to Blacklist modal -->
    <div class="modal fade" id="addToBlacklistModal" tabindex="-1" aria-labelledby="addToBlacklistTitle" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content p-h-xxl p-v-xxl">
                <div class="modal-title">
                    <h4 v-show="currentEvent !== null" class="m-r-sm textAlign-center"> {{$t('blacklist.add_to_blacklist')}} </h4>
                    <p class="modal-sub-title"> {{$t('blacklist.blacklist_modal_message')}} {{currentEvent?.vehicle.plate}}</p>
                    <select class="form-select m-b-md" v-model="reason">
                        <option value="" selected > Select A Reason </option>
                        <option v-for="reason in blacklistReasons" :value="reason" :key="reason"> {{reason}} </option>
                    </select>
                    <div class="flex-center">
                        <button class="btn btn-secondary m-r-sm" @click="() => addToBlacklistModal.hide()"> {{$t('global.close')}} </button>
                        <button class="btn btn-primary bg-danger" style="border-color: #DC3545" @click="addToBlacklist"> <i class="material-icons-outlined"> add </i> {{$t('blacklist.add_to_blacklist')}} </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Remove from Blacklist Modal -->
    <div class="modal fade" id="unblacklistModal" tabindex="-1" aria-labelledby="unblacklistModal" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content p-h-xxl p-v-xxl">
                <div class="modal-title">
                    <h4 v-show="currentEvent !== null" class="m-r-sm textAlign-center"> {{$t('blacklist.remove_from_blacklist')}} </h4>
                </div>
                <div class="flex p-v-md">
                    <i class="material-icons warning-icon m-r-sm"> warning_amber </i>
                    <div>
                        <h4 class="text-center"> {{$t('blacklist.are_you_sure')}} ? </h4>
                        <span> {{$t('blacklist.modal_message')}} </span>
                    </div>
                </div>
                <div class="flex-center">
                    <button class="btn btn-secondary m-r-sm" @click="() => unblacklistModal.hide()"> {{$t('global.cancel')}} </button>
                    <button class="btn btn-primary" @click="unblacklist"> <i class="material-icons-outlined "> close </i> {{$t('blacklist.remove_from_blacklist')}} </button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
//Components
import {Form, Field, ErrorMessage} from 'vee-validate';
import Pagination from '../../components/Pagination.vue';
// import EventImage from '../../components/EventImage.vue';
import SasEventImage from '../../components/SasEventImage.vue';
import Timestamp from '../../components/Timestamp.vue';
import { Multiselect } from 'vue-multiselect';

//Dependencies 
import * as yup from 'yup';
import CameraService from '../../services/camera.service';
import ClientService from '../../services/client.service';
import eventService from '../../services/event.service';

export default {
    name: 'EventsNew',
    components: {Form, Field, ErrorMessage, Pagination, Timestamp, Multiselect, SasEventImage},
    props: {
        registration: String,
        site_id: String | Number,
        start_date: String,
        end_date: String,
        start_time: String,
        end_time: String,
        direction: String,
        status: String,
        site: Object
    },
    data() {
        const schema = yup.object().shape({
            registration: yup.string().min(3, 'Must be at least 3 characters!').max(12, 'Must not be more than 12 characters!').optional().nullable(),
            start_date: yup.date().max(new Date('9999-12-31'), 'Surpassed year limit, Please check Date.').optional().nullable(),
            end_date: yup.date().max(new Date('9999-12-31'), 'Surpassed year limit, Please check Date.').optional().nullable(),
            start_time: yup.string().matches('^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$', 'Must be a valid HH:mm time').optional().nullable(),
            end_time: yup.string().matches('^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$', 'Must be a valid HH:mm time').optional().nullable(),
            direction: yup.string().optional().nullable(),
            status: yup.string().optional().nullable()
        })
        return {
            loading: true,   
            events: [],
            pagination: {
                page: 0,
                showing: 0,
                total: 0,
                lastPage: 0,
                maxPage: 0
            },
            schema,
            filters: {
                registration: null,
                site: null,
                start_date: null,
                end_date: null,
                start_time: null,
                end_time: null,
                direction: null,
                status: null,
                camera: null
            },
            sites: [],
            cameras: [],
            search: {
                client_id: null
            },
            currentParams: {},
            currentEvent: null,  
            clientID: this.$store.state.auth.user.selectedClient,
            addToBlacklistModal: null,
            unblacklistModal: null,
            blacklistReasons: [
                "Drive Off",
                "No Means of Payment",
                "Fly Tipping",
                "Theft",
                "Aggressive",
                "Plate Mismatch"
            ],
            reason: ""
        }
    },
    mounted() {
        // this.loadEvents();

        let previousRoute = this.$router.options.history.state.forward;
        if(!previousRoute) previousRoute = this.$router.options.history.state.back;
        console.log(previousRoute);
        if(previousRoute){
            if(previousRoute.includes('/eventsnew/')){
                console.log('Previous route was probably an event');
            }else{
                console.log('Previous route was not an event')
                localStorage.removeItem('varsportal::eventsParams::' + this.clientID);
                localStorage.removeItem('varsportal::eventsPagination::' + this.clientID);
            }
        }

        this.loadSites();
        this.loadCameras();
        this.addToBlacklistModal = new bootstrap.Modal(document.getElementById('addToBlacklistModal'));
        this.unblacklistModal = new bootstrap.Modal(document.getElementById('unblacklistModal'));

        if(this.isValid(this.status)) {
            this.filters.status = this.status;
        }
        if(this.isValid(this.start_date)) {
            this.filters.start_date = this.start_date;
        }
        if(this.isValid(this.end_date)) {
            this.filters.end_date = this.end_date;
        }
        if(this.isValid(this.start_time)) {
            this.filters.start_time = this.start_time;
        }
        if(this.isValid(this.end_time)) {
            this.filters.end_time = this.end_time;
        }
        if(this.isValid(this.site)) {
            let site = JSON.parse(this.site);
            if(this.sites.length > 0){
                this.filters.site = this.sites.find(site => parseInt(site.id) === parseInt(site.id));
            }else{
                this.filters.site = site;
            }
        }

        if(this.status && this.start_date && this.end_date) {
            this.loadEvents(1, this.filters);
            return;
        }
        let params = JSON.parse(localStorage.getItem('varsportal::eventsParams::' + this.clientID));
        let pagination = JSON.parse(localStorage.getItem('varsportal::eventsPagination::' + this.clientID));
        if(pagination) {
            this.pagination = pagination;
        }
        if(params) {
            this.filters = params;
            this.loadEvents(this.pagination.page || 1, params);
            return;
        }
        this.loadEvents();
    },
    methods: {
        modalImageLoaded(){
            console.log("Modal image loaded");
        },
        loadEvents(page = 1, params = {}) {
            console.log(params);
            this.loading = true;
            this.currentParams = params;
            params.site = this.filters.site?.id || null;
            params.camera = this.filters.camera?.id || null;
            // console.log(this.currentParamas);
            localStorage.setItem('varsportal::eventsParams::' + this.clientID, JSON.stringify(this.currentParams));
            eventService.getEvents(page, this.clientID, params)
            .then(response => {
                this.events = response.data.events;
                this.pagination = response.data.pagination;
                localStorage.setItem('varsportal::eventsPagination::' + this.clientID, JSON.stringify(this.pagination));
                this.loading = false;
            })
            .catch(error => {
                this.$error(this.$t('events.events_error', error));
                this.loading = false;
            })
        },
        loadSites() {
            ClientService.getClientSites(this.$store.state.auth.user.selectedClient, true)
            .then(response => {
                this.sites = response.data.sort((a, b) => {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                });
                this.sites.forEach((site) => {
                    if(site.customer_reference){
                        site.name = '(' + site.customer_reference + ') ' + site.name;
                    }
                });
                if(this.filters.site?.id){
                    this.filters.site = this.sites.find(site => parseInt(site.id) === parseInt(this.filters.site.id));
                }
            })
            .catch(error => {
                this.$error(this.$t('sites.site_load_error'), error);
            })
        },
        loadCameras(site = null) {
            CameraService.getCameras(site)
            .then(response => {
                this.cameras = response.data.cameras;
            })
            .catch(error => {
                this.$error(this.$t('event.camera_error'), error);
            });
        },
        clearFilter() {
            // resetForm();
            // this.loadEvents(1, {});
            this.filters.registration = null;
            this.filters.site = null;
            this.filters.start_date = null;
            this.filters.end_date = null;
            this.filters.start_time = null;
            this.filters.end_time = null;
            this.filters.direction = null;
            this.filters.status = null;
            this.filters.camera = null;
        },
        openEventImage(event) {
            this.currentEvent = event;
            $('#eventImageModal').modal('toggle');
            // console.log(document.getElementById(`eventimage-${this.currentEvent.id}`));
            // this.magnify(3);
        },
        openModal(event) {
            this.currentEvent = event;
            this.addToBlacklistModal.show();
        },
        addToBlacklist() {
            if(this.reason) {
                eventService.blacklist(this.currentEvent.site.id, this.currentEvent.id, this.reason)
                .then(response => {
                    console.log(response);
                    this.currentEvent.status = 'blacklisted';
                    this.events.forEach(event => {
                        if(event.vehicle.plate === this.currentEvent.vehicle.plate) {
                            event.status = 'blacklisted';
                        }
                    });
                    this.$success(`Added ${this.currentEvent.vehicle.plate} to blacklist.`);
                    this.addToBlacklistModal.hide();
                })
                .catch(error => {
                    console.log(error);
                    this.$error(this.$t('blacklist.error_message'), error);
                });
            }   
        },
        unblacklist() {
            eventService.unblacklist(this.currentEvent.site.id, this.currentEvent.id)
            .then(response => {
                this.currentEvent.status = 'active';
                this.events.forEach(event => {
                    if(event.vehicle.plate === this.currentEvent.vehicle.plate) {
                        event.status = 'active';
                    }
                });
                this.$success(this.$t('blacklist.unblacklist_success'));
            })
            .catch(error => {
                this.$error(this.$t('blacklist.unblacklist_error'), error);
            })
            .finally(() => {
                this.unblacklistModal.hide();
            }) 
        },
        openUnblacklistModal(event) {
            this.currentEvent = event;
            this.unblacklistModal.show();
        },
        isValid(value) {
            if(value === null) return false;
            if(value === undefined) return false;

            if(typeof value === 'string') {
                if(value.length < 1) return false;
            }

            return true;
        }
    },
}
</script>

<style scoped>

.events-container {
    margin-top: 20px;
    justify-content: space-between;
}

/* also in custom.css */
.card {
    width: 400px;
    border-radius: 10px;
    /* background-color: #232330; */
    cursor: pointer;
}

.card:hover {
    box-shadow: 5px 5px 5px rgba(255, 255, 255, 0.1);
}

.image-container {
    margin: 10px 0;
    border-radius: 10px 10px 0 0;
}

.event-information-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.event-information-container div {
    margin: 5px 0;
}

#site-name {
    font-size: 18px;
    font-weight: 500;
}

#camera-name, #camera-direction, #camera-direction, #timestamp {
    font-size: 16px;
}

.information-sub-container {
    display: flex;
    justify-content: space-between;
}

.status-container {
    border: 2px solid var(--utility-green); 
    border-radius: 50px;
    padding: 5px 10px;
    color: var(--utility-green);
    font-weight: 400;
}

/* .information-sub-container button {
    background-color: rgba(220, 53, 69, 0.3);
    border: 2px solid transparent;
    color: var(--utility-red);
} */

.information-sub-container button:hover {
    border: 2px solid var(--utility-red);
}

.btn-container {
    margin-top: 10px !important;
}

.red {
    color: var(--utility-red);
    background-color: rgba(220, 53, 69, .3);
}

.green {
    color: var(--utility-green);
    background-color: rgba(75, 173, 72, .3);
}

.red, .green {
    border-radius: 50px;
    padding: 5px 10px;
    font-weight: 600;
}

/* .secondary-blue-btn {
    background-color: rgba(34, 105, 245, .3) !important;
    color: var(--utility-blue) !important;
} */

.secondary-blue-btn:hover {
    border-color: var(--utility-blue) !important;
}

.blacklist-card {
    border: 2px solid var(--utility-red);
}

.no-data-container {
    color: var(--utility-orange);
    font-style: italic;
    font-size: 16px;
    font-size: bold;
}

/* .modal-sub-title {
    font-weight: 100;
    color: #CDC4D1;
} */

.img-magnifier-container {
  position: relative;
}

.img-magnifier-glass {
  position: absolute;
  border: 3px solid #000;
  border-radius: 50%;
  cursor: none;
  width: 100px;
  height: 100px;
}

.warning-icon {
    font-size: 50px;
    color: var(--utility-orange);
}

.flex {
    flex-wrap: nowrap;
    /* padding: 10px 0; */
}

</style>