<template>
    <div>
        <!-- Loading -->
        <div v-show="imageLoading" class="parent-div" style="width: 100%;">
            <div class="spinner-border center-div" style="height: 4rem; width: 4rem;" role="status">
                <span class="visually-hidden">{{ $t('events.loading') }}...</span>
            </div>
        </div>

        <!-- Image -->
        <img :id="id" v-show="!imageLoading" ref="img" v-if="src !== null && viewer" :src="src" @load="imageLoading = false" @click="$emit('click')" v-viewer>
        <img :id="id" v-show="!imageLoading" ref="img" v-else-if="src !== null" :src="src" @load="imageLoading = false" @click="$emit('click')">
    </div>
</template>

<script>
import axios from 'axios';
import authHeader from '../services/auth-header';
export default {
    name: 'EventImage',
    emits: ['loaded', 'click'],
    props: {
        filename: String,
        site_id: String,
        id: String,
        storage: String | Number,
        thumb: {
            default: true,
            required: false,
            type: Boolean
        },
        viewer: {
            default: true,
            required: false,
            type: Boolean
        },
        src: String
    },
    data(){
        return {
            imageLoading: true,
            img: null,
            glass: null,
            bw: 3,
            w: 0,
            h: 0
        }
    },
    mounted() {
        
    },
    methods: {
          
    },
    watch: {
        filename: function(val){
            this.loadImage();
        },
        site_id: function(val){
            this.loadImage();
        },
        endpoint: function(val){
            this.loadImage();
        },
        thumb: function(val){
            this.loadImage();
        }
    }
}
</script>

<style scoped>

img {
    width: 100%;
    border-radius: 10px 10px 0 0;
}

</style>